<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div class="uk-inline">
          <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
          <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'name')" type="text" placeholder="Search property name">
        </div>
        <div class="uk-form-controls uk-width-small">
          <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
            <option :value=null>Select status</option>
            <option :value=1>Active</option>
            <option :value=0>Non Active</option>
          </select>
        </div>
        <div class="uk-inline">
          <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="showModal">Create</button>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th class="uk-text-center">Company Name</th>
              <th class="uk-text-center">Type</th>
              <th class="uk-text-center">Code</th>
              <th class="uk-text-center">Name</th>
              <th class="uk-text-center uk-width-1-5">Address</th>
              <th class="uk-text-center">Notes</th>
              <th class="uk-text-center">Status</th>
              <th class="uk-text-center uk-width-1-5">Action</th>
            </tr>
          </thead>
          <tbody v-if="properties.docs.length>0" class="uk-table-middle uk-text-center ">
            <tr v-for="(property, i) in properties.docs" :key="i">
              <td>{{property.company.name}}</td>
              <td>{{property.type.code}} ({{property.type.description}})</td>
              <td>{{property.code}}</td>
              <td>{{property.name}}</td>
              <td>{{property.address}}</td>
              <td>{{property.notes}}</td>
              <td>
                <div class="label uk-margin-auto" :style="`background-color: ${property.status ? '#00cc00' : '#e63c3c'}`">
                  {{property.status ? 'Active' : 'Non Active'}}
                </div>
              </td>
              <td>
                <button class="uk-button uk-button-small uk-button-default" type="button">Actions</button>
                <div uk-dropdown="mode: click">
                  <ul class="uk-nav uk-dropdown-nav uk-text-left">
                    <li><a v-clipboard:copy="property._id"
                  v-clipboard:success="onCopy">Copy ID</a>
                    </li>
                    <li class="uk-nav-divider"></li>
                    <li class="uk-nav-header">Data Action</li>
                    <li><a @click="editProperty(property)">Edit</a></li>
                    <li><a @click="showDeleteModal(property)">Delete</a></li>
                    <li class="uk-nav-divider"></li>
                    <li><a uk-toggle="target: #jobListsModal" @click="getAllJobLists(property.company._id,property._id)">Generate QR Code</a></li>
                    <div id="jobListsModal" class="modal-close-default uk-flex-top" uk-modal esc-close="false" bg-close="false">
                      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-body">
                            <template v-if="job_lists.length > 0">
                              <div class="uk-text-center">
                                <button v-for="item in job_lists" class="uk-button uk-button-primary" type="button" uk-toggle="target: #qrModal"
                                @click="setQrValue(item.company_id, item.company_office_id, item._id)"
                                :key="item._id" style="margin: 2px">{{item.title}}</button>
                              </div>
                            </template>
                            <template v-else>
                              <p>List Pekerjaan tidak tersedia</p>
                            </template>
                        </div>
                      </div>
                    </div>
                    <div id="qrModal" class="modal-close-default uk-flex-top" uk-modal esc-close="false" bg-close="false">
                      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-body">
                          <qrcode style="padding: 1px; border: 1px solid #ccc; border-radius: 25px; margin: auto; display: block;"
                            :value="`company_id=${qrCompanyId}&warehouse_id=${qrWarehouseId}&job_id=${qrJobId}`"
                            :options='{ width: 200, color: { dark: "#000000", light: "#FFFFFF"} }' ></qrcode>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
          <empty-table v-else :colspan="6" />
        </table>
      </div>
      <pagination
        :total-data="properties.totalDocs"
        :change-limit="changeLimit"
        :change-page="changePage"
      />
    </div>

    <div id="formPropertyModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Property Form</h2>
        </div>
        <div class="uk-modal-body">

          <div class="uk-margin">
            <label class="uk-form-label">Company <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="propertyForm.company_id"
              placeholder="Type to search company"
              label="name"
              name="company"
              track-by="name"
              v-validate="'required'"
              :options="company_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setCompanyValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('company')">{{ errors.first('company') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Property Type <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="propertyForm.type_id"
              placeholder="Type to search property type code"
              label="name"
              name="type"
              track-by="name"
              v-validate="'required'"
              :options="type_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setTypeValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('type')">{{ errors.first('type') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Property Name <b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('property_name')}"
              name="property_name"
              type="text"
              v-model="propertyForm.name"
              v-validate="'required'"
              placeholder="Warehouse Sparepart">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('property_name')">{{ errors.first('property_name') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Property Code <b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('property_code')}"
              name="property_code"
              type="text"
              v-model="propertyForm.code"
              v-validate="'required'"
              placeholder="WS">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('property_code')">{{ errors.first('property_code') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Property City <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="propertyForm.city"
              placeholder="Select City"
              name="property_city"
              v-validate="'required'"
              :options="city_options"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('property_city')">{{ errors.first('property_city') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Property Address <b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('property_address')}"
              name="property_address"
              type="text"
              v-model="propertyForm.address"
              v-validate="'required'"
              placeholder="Jl. Rasuna Said 14">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('property_address')">{{ errors.first('property_address') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Coordinate Longitude</label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('property_longitude')}"
              name="property_longitude"
              type="text"
              v-model="propertyForm.geoloc.lng"
              v-validate="'decimal'"
              placeholder="-6.175110">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('property_longitude')">{{ errors.first('property_longitude') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Coordinate Latitude </label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('property_latitude')}"
              name="property_latitude"
              type="text"
              v-model="propertyForm.geoloc.lat"
              v-validate="'decimal'"
              placeholder="Jl. Rasuna Said 14">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('property_latitude')">{{ errors.first('property_latitude') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Property Notes <b class="uk-text-danger">*</b></label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('property_notes')}"
              name="property_notes"
              type="text"
              v-model="propertyForm.notes"
              v-validate="'required'"
              placeholder="Notes">
            <span class="uk-text-small uk-text-danger" v-show="errors.has('property_notes')">{{ errors.first('property_notes') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Property Status <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="propertyForm.status"
              placeholder="Select Status"
              label="name"
              name="property_status"
              track-by="name"
              v-validate="'required'"
              :options="status_options">
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('property_status')">{{ errors.first('property_status') }}</span>
          </div>

        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-primary" type="button" @click="saveProperty">Save</button>
        </div>
      </div>
    </div>

    <div id="deletePropertyModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideDeleteModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Delete Property?</h2>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin-small">
            <div>
              <h5>Company Name:</h5>
              <p>{{propertyForm.company_id.name}}</p>
            </div>
            <div>
              <h5>Type:</h5>
              <p>{{propertyForm.type_id.name}}</p>
            </div>
            <div>
              <h5>Property Name:</h5>
              <p>{{propertyForm.name}}</p>
            </div>
            <div>
              <h5>Property Code:</h5>
              <p>{{propertyForm.code}}</p>
            </div>
            <div>
              <h5>Property Address:</h5>
              <p>{{propertyForm.address}}</p>
            </div>
            <div>
              <h5>Coordinate Longitude:</h5>
              <p>{{propertyForm.geoloc !== null ? propertyForm.geoloc.lng : 0 }}</p>
            </div>
            <div>
              <h5>Coordinate Latitude:</h5>
              <p>{{propertyForm.geoloc !== null ? propertyForm.geoloc.lat : 0}}</p>
            </div>
            <div>
              <h5>Notes:</h5>
              <p>{{propertyForm.notes}}</p>
            </div>
            <div>
              <h5>Status:</h5>
              <p>{{propertyForm.status.name}}</p>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-danger" type="button" @click="markPropertyDeleted">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import axios from "axios";
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import Vue from 'vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';

Vue.component(VueQrcode.name, VueQrcode);

export default {
    data() {
        return {
            meta: {
                limit: 100,
                page: 1,
                code: '',
                name: '',
                status: null,
                is_deleted: false,
            },
            propertyForm: {
                company_id: '',
                type_id: '',
                code: '',
                name: '',
                city: '',
                address: '',
                notes: '',
                status: '',
                geoloc: {
                    lat: 0,
                    lng: 0
                }
            },
            isLoading: false,
            formIsEdit: false,
            status_options: [
                { name: 'Active', value: 1},
                { name: 'Non Active', value: 0}
            ],
            city_options: [],
            company_options: [],
            type_options: [],
            isFetching: false,
            qrCompanyId: '',
            qrWarehouseId: '',
            qrJobId: ''
        };
    },
    components: {
        Pagination,
        EmptyTable
    },
    watch: {
        meta() {
            this.setPropertyMeta();
        },
    },
    mounted() {
        this.setPropertyMeta();
        this.getCityList();
    },
    computed: {
        ...mapGetters({
            properties: 'property/properties',
            companies: 'company/companies',
            property_types: 'property_type/property_types',
            job_lists: 'job/job_lists',
        })
    },
    methods: {
        ...mapActions({
            getProperties: 'property/getProperties',
            createProperty: 'property/createProperty',
            updateProperty: 'property/updateProperty',
            deleteProperty: 'property/deleteProperty',
            getCompanies: 'company/getCompanies',
            getPropertyTypes: 'property_type/getPropertyTypes',
            getJobLists: 'job/getJobLists',
        }),
        setPropertyMeta() {
            this.getProperties(this.meta);
        },
        async getAllJobLists(company_id, company_office_id) {
            let data = {company_id: company_id, company_office_id: company_office_id};
            await this.getJobLists(data);
        },
        setQrValue(company_id, company_office_id, job_id) {
            this.qrCompanyId = company_id;
            this.qrWarehouseId = company_office_id;
            this.qrJobId = job_id;
        },
        async setCompanyValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setTypeValues(query) {
            this.isFetching = true;

            await this.getPropertyTypes({code: query});
            this.type_options = this.property_types.docs.map(
                obj => ({name: obj.code, value: obj._id})
            );

            this.isFetching = false;
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
            this.getProperties(this.meta);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        fillForm(property) {
            try {
                this.propertyForm.id = property._id;
                this.propertyForm.code = property.code;
                this.propertyForm.city = property.city;
                this.propertyForm.name = property.name;
                this.propertyForm.address = property.address;
                this.propertyForm.notes = property.notes;
                this.propertyForm.status = this.status_options.find(
                    e => e.value === property.status
                );
                if (property.geoloc != null) {
                    this.propertyForm.geoloc = property.geoloc;
                }
                else{
                    this.propertyForm.geoloc.lat = 0;
                    this.propertyForm.geoloc.lng = 0;
                }

                this.propertyForm.company_id = {
                    name: property.company.name,
                    value: property.company._id
                };
                this.propertyForm.type_id = {
                    name: property.type.code,
                    value: property.type._id
                };
            } catch (err) {
                notificationDanger(err);
            }
        },
        editProperty(property) {
            this.formIsEdit = true;
            this.fillForm(property);
            this.showModal();
        },
        async saveProperty() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                this.propertyForm.company_id = this.propertyForm.company_id.value;
                this.propertyForm.type_id = this.propertyForm.type_id.value;
                this.propertyForm.status = this.propertyForm.status.value;

                const response = this.formIsEdit
                    ? await this.updateProperty(this.propertyForm)
                    : await this.createProperty(this.propertyForm);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Property saved!');
                    this.hideModal();
                    this.setPropertyMeta();
                } else {
                    notificationDanger('Failed to save property.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async showModal() {
            await window.UIkit.modal('#formPropertyModal').show();
        },
        async hideModal() {
            await window.UIkit.modal('#formPropertyModal').hide();
            this.resetForm();
        },
        resetForm() {
            this.formIsEdit = false;
            this.propertyForm = {
                company_id: '',
                type_id: '',
                code: '',
                name: '',
                city: '',
                address: '',
                notes: '',
                status: '',
                geoloc: {
                    lat: 0,
                    lng: 0
                }
            };
            this.$validator.reset();
        },
        async markPropertyDeleted() {
            try {
                this.isLoading = true;
                const response = await this.deleteProperty(this.propertyForm.id);
                this.isLoading = false;

                if (response && response.status === 'OK') {
                    notificationSuccess('Property deleted!');
                    this.hideDeleteModal();
                    this.setPropertyMeta();
                } else {
                    notificationDanger('Failed to delete property.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async showDeleteModal(property) {
            this.fillForm(property);
            await window.UIkit.modal('#deletePropertyModal').show();
        },
        async hideDeleteModal() {
            await window.UIkit.modal('#deletePropertyModal').hide();
            this.resetForm();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        getCityList() {
            axios.get('/static/kota-kabupaten.json').then(res => {
                this.city_options = res.data;
            }).catch((err) => {
                notificationDanger(err);
            });
        }
    }
};
</script>
